import { Col, Row } from "react-bootstrap";
import { TeamCard } from "../Team/Card";
import blogStyles from "@/styles/blog.module.scss";
import { teamPortfolioContent } from "@/content/team.content";
import Slider from "react-slick";
import { teamSliderSettings as settings } from "@/configs/slider.config";

const Team = () => {
  return (
    <>
      <Row className='text-center'>
        <h1 className={`mb-3 ${blogStyles.h1} mt-20`}>
          Executive <span className='text-gradient'>Team</span>
        </h1>
        <p>Pioneers of Metachain Technologies Inc. are the best in their fields.</p>
      </Row>

      <Row className={`mt-4 d-none d-md-block`}>
        <Col md={10} className='mx-auto'>
          <Row>
            {Object.values(teamPortfolioContent).map((item) => (
              <Col md={4} key={item.name}>
                <TeamCard {...item} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className={"justify-content-center align-items-center mt-4 d-flex d-md-none"}>
        <Col sm={10}>
          <Row className='slider-theme owl-theme owl-stage-outer-pb-5 pb-0'>
            <Slider {...settings}>
              {Object.values(teamPortfolioContent).map((item) => (
                <TeamCard {...item} key={item.name} />
              ))}
            </Slider>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Team;
