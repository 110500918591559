import { Col, Row } from "react-bootstrap";
import styles from "@/styles/news.module.scss";
import { news } from "@/configs/news.config";
import Image from "next/image";
import NewsImg from "@/assets/img/news.png";
import LineBetween from "@/assets/svg/news-line.svg";
import LinesHirarechy from "@/assets/svg/news-hirarechy.svg";
import LineBetweenMob from "@/assets/svg/news-line-mob.svg";
import LinesHirarechyMob from "@/assets/svg/news-hirarechy-mob.svg";
import { nanoid } from "nanoid";
import { arrayChunk } from "@/utils/helpers/array";

const News = () => {
  return (
    <Row className={`justify-content-center align-items-center my-20  ${styles["zoom-min"]}`}>
      <Col
        xs={12}
        md={10}
        xl={10}
        className='d-flex flex-lg-row flex-column align-items-center justify-content-center flex-wrap'
      >
        <div className={styles["news-img-box"]} style={{ flexGrow: 1 }}>
          <Image src={NewsImg} alt='News' layout='responsive' />
        </div>
        <div className='line-container' style={{ flexGrow: 0.5 }}>
          <Image src={LineBetween} alt='Line' layout='responsive' className='d-none d-lg-block' />
          <Image
            src={LineBetweenMob}
            alt='Line'
            layout='responsive'
            className='d-block d-lg-none'
          />
        </div>

        <div className={styles["news-title"]} style={{ flexGrow: 1 }}>
          <h2 className='text-center'>
            Metachain
            <br /> in News
          </h2>
        </div>

        <div className='line-container' style={{ flexGrow: 0.5 }}>
          <Image
            src={LinesHirarechy}
            alt='Line'
            layout='responsive'
            className='d-none d-lg-block'
          />
          <Image
            src={LinesHirarechyMob}
            alt='Line'
            layout='responsive'
            className={` d-custom  d-lg-none ${styles["mob-heirarechy"]}`}
          />
          <Image
            src={LineBetweenMob}
            alt='Line'
            layout='responsive'
            className='d-after-custom d-none'
          />
        </div>

        <div className={`d-flex flex-column gap-4 ${styles["high-gap"]}`} style={{ flexGrow: 1 }}>
          {arrayChunk(news, 4).map((item, index) => (
            <div
              className={`${styles["border-grad-news"]} ${
                index % 2 === 0 ? styles["left"] : styles["right"]
              }`}
              key={index + nanoid()}
            >
              <div className={`${styles["news-content"]}`} key={index}>
                {item.map((item) => (
                  <a
                    href={item.link}
                    className='scale-up extra-scale d-flex justify-content-center'
                    key={item.id}
                  >
                    <Image src={item.logo} alt='News' layout='responsive' className='w-75' />
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default News;
