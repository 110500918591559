import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "@/styles/home.module.scss";
import { BiRightArrowAlt } from "react-icons/bi";
import CalendlyWidget from "../common/Calendly";
import { useCalendlyModal } from "@/contexts/Calendly";

const Hero: FC = () => {
  const { toggleModal } = useCalendlyModal();

  return (
    <Row className={styles.hero}>
      <Col
        md={10}
        className=' mx-auto d-flex flex-column align-items-center justify-content-center'
      >
        <h1 className='text-center mb-4'>
          Metachain <br /> <span>Technologies Inc.</span>{" "}
        </h1>
        <Row className='mb-2'>
          <p className='col-md-10 mx-auto'>
            A Private Canadian software development company with a worldwide team focused on
            developing technologies in MetaVerse, AI, NFTs, and blockchain.
          </p>
        </Row>

        <div className='d-flex hero-action gap-2' onClick={toggleModal}>
          <button className='btn btn-primary home-btn'>Book a Call</button>
          <span className='arr-right-btn d-flex align-items-center justify-content-center position-relative'>
            <BiRightArrowAlt />
            <BiRightArrowAlt className='position-absolute' />
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default Hero;
