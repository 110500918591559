import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "@/styles/services.module.scss";
import Link from "next/link";
import { services } from "@/content/home.content";
import Image from "next/image";
import BaseImg from "@/assets/img/services/base.png";
import { CgArrowRight } from "react-icons/cg";
import BallonImg from "@/assets/img/services/pins/up.png";
import RightPin from "@/assets/img/services/pins/right.svg";
import DownPin from "@/assets/img/services/pins/down.png";

const Card: FC<any> = ({ name, breif, content, path, img, ...rest }) => {
  return (
    <Row {...rest}>
      <Col
        xl={3}
        lg={4}
        className={`${styles.shape} d-flex justify-content-center align-items-center `}
      >
        <div className={`${styles.images} position-relative`}>
          <Image src={img} alt={name} width={0} height={0} className='  ms-2' />
          <Image
            src={BaseImg}
            alt={"base image"}
            width={0}
            height={0}
            className=' position-absolute '
            blurDataURL={BaseImg.blurDataURL}
          />
        </div>
      </Col>
      <Col xl={9} lg={8} className={`${styles.content}`}>
        <h2>{name}</h2>
        <h3>{breif}</h3>
        <p>{content}</p>
        <Link className='text-white d-flex align-items-center read-more' href={path}>
          Read More
          <span className='position-relative d-flex justify-content-center align-items-center'>
            <CgArrowRight className='ms-1 my-auto' />
            <CgArrowRight className='ms-1 my-auto position-absolute' />
          </span>
        </Link>
      </Col>
    </Row>
  );
};

const Services: FC = () => {
  return (
    <>
      <div className='d-flex ballon-div'>
        <Image
          src={BallonImg}
          alt={"ballon"}
          width={0}
          height={0}
          className='mx-auto position-relative ballon-width h-auto'
        />
      </div>
      <Row className={`mt-4`}>
        <Col md={10} lg={8} className={`mx-auto ${styles.borderSection} py-special`}>
          <Row>
            <Col
              md={1}
              style={{ maxWidth: "4.1667%" }}
              className='m-0 p-0 d-none d-md-flex flex-column justify-content-around pins-col '
            >
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin left'
              />
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin left'
              />
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin left'
              />
            </Col>
            <Col xs={11} md={11} className='mx-auto'>
              {services.map((service, index, arr) => (
                <Card
                  className={`${styles.card} ${index !== arr.length - 1 ? "mb-10" : null}`}
                  key={service.name + index}
                  {...service}
                />
              ))}
            </Col>
            <Col
              style={{ maxWidth: "4.1667%" }}
              md={1}
              className='m-0 p-0 d-none d-md-flex flex-column justify-content-around pins-col'
            >
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin right'
              />
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin right'
              />
              <Image
                src={RightPin}
                alt={"left pin"}
                width={0}
                height={0}
                className='h-auto pin right'
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='d-flex mt--1'>
        <Image
          src={DownPin}
          alt={"oin"}
          width={0}
          height={0}
          className='mx-auto position-relative'
        />
      </div>
    </>
  );
};

export default Services;
