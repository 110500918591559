import { Row } from "react-bootstrap";
import blogStyles from "@/styles/blog.module.scss";
import { projectsSliderSettings } from "@/configs/slider.config";
import { projectsData } from "@/content/projects.content";
import { FC } from "react";
import styles from "@/styles/project.module.scss";
import { FaArrowRight } from "react-icons/fa";
import BG from "@/assets/img/projects-bg.png";
import Image from "next/image";
import { handleNavigationOutside } from "@/utils/helpers";
import Slider from "react-slick";

const Card: FC<any> = ({ name, content, linkName, link, current }) => {
  return (
    <div className={`${styles.item} position-relative ${current && styles.current}`}>
      <h4 className='mb-3 mt-1'>{name}</h4>
      <p className='truncate lines-5'>{content[0]}</p>
      <div className='position-absolute d-flex align-items-center  text-white w-100'>
        <span className='cursor-pointer' onClick={handleNavigationOutside(link)}>
          <FaArrowRight />
        </span>

        <a href={link} target='_blank' rel='noreferrer' className='text-end '>
          {linkName}
        </a>
      </div>
    </div>
  );
};

const Projects = () => {
  return (
    <>
      <Row className='text-center w-75 mx-auto' id={"projects"}>
        <h1 className={`mb-3 ${blogStyles.h1} mt-20`}>
          Our <span className='text-gradient '>Projects</span>
        </h1>
        <p>
          Metachain Technologies Inc. is a company that opens the doors of opportunity of the
          MetaVerse to large firms.
        </p>
      </Row>

      <div className='mb-10'>
        <Row className={`mt-4 position-relative`}>
          <Image
            src={BG}
            alt={"bg"}
            width={0}
            height={0}
            className={`${styles["pro-img"]} position-absolute w-100 h-auto`}
          />
          <Slider {...projectsSliderSettings} className='w-100'>
            {projectsData.map((item) => (
              <div key={item.id} className='swiper-slide '>
                <Card {...item} />
              </div>
            ))}
          </Slider>
        </Row>
      </div>
    </>
  );
};

export default Projects;
