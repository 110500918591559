import Image, { StaticImageData } from "next/image";
import { FC } from "react";
import { Card } from "react-bootstrap";
import styles from "@/styles/blog.module.scss";

export const BlogCard: FC<{
  title: string;
  img: { src: StaticImageData; alt: string; title: string };
  link: string;
  category: string;
  publishedAt: string;
}> = ({ title, img, link, category, publishedAt }) => {
  return (
    <Card className={`${styles["blog-item"]} blog-card`}>
      <div className='blog-img-aspect overflow-hidden'>
        <Image
          className='card-img-top w-100 img-fluid blog-img-aspect'
          {...img}
          width={0}
          height={0}
        />
      </div>

      <Card.Body className={styles["card-body"]}>
        <Card.Text>
          <span className='category-item text-sm fw-semibold text-white me-2'>{category}</span>{" "}
          {publishedAt}
        </Card.Text>
        <Card.Title className={`text-white truncate ${styles["card-title"]}`}>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
};
