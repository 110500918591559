import BW from "../assets/img/news/bw.svg";
import Proactive from "../assets/img/news/proactive.svg";
import Tracxn from "../assets/img/news/tracxn.svg";
import PB from "../assets/img/news/pb.svg";
import Yahoo from "../assets/img/news/yahoo.svg";
import Prai from "../assets/img/news/prai.svg";
import CB from "../assets/img/news/cb.svg";
import Bloomberg from "../assets/img/news/bloomberg.svg";
import ESE from "../assets/img/news/ese.svg";
import AiThority from "../assets/img/news/aithority.svg";
import Cision from "../assets/img/news/cision.svg";
import FinancialTimes from "../assets/img/news/fin-times.svg";
import { StaticImageData } from "next/image";

interface News {
  id: string;
  link: string;
  logo: StaticImageData;
}

export const news: News[] = [
  {
    id: "businesswire",
    link: "https://www.businesswire.com/news/home/20211122005867/en/Revitalist-Acquires-Revitaland-Meta-Tech-to-Expand-Virtual-Clinics-in-the-Metaverse",
    logo: BW,
  },
  {
    id: "proactiveinvestors",
    link: "https://www.proactiveinvestors.ca/companies/news/975579/ese-metaverse-division-partners-with-metachain-technologies-to-develop-applications-on-blockchain-and-metaverse-975579.html",
    logo: Proactive,
  },
  {
    id: "tracxn",
    link: "https://tracxn.com/d/companies/metachain-technologies/__a4NWjlNdW8JsTh1DQTQcyJJX5r-cw0LuEiOAbBUl_54",
    logo: Tracxn,
  },
  {
    id: "aithority",
    link: "https://aithority.com/machine-learning/genai-launches-artificial-intelligence-project-to-create-a-newswire-service-for-investors",
    logo: AiThority,
  },
  {
    id: "pitchbook",
    link: "https://pitchbook.com/profiles/company/519089-23",
    logo: PB,
  },
  {
    id: "yahoo finance",
    link: "https://finance.yahoo.com/news/ese-metaverse-division-partners-metachain-110000237.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAACX1WZN4v3Z1BFmRfTU3HkjXhMMCwT8Z2k1GdpVbee4qNBDE-HABDOC86V0eHNr13A-vkz1gvPf3TEUdZv5Zao_4LUMZXYGohAHG3QRjBEN96BoNq5LP2BspmSjpC6LIuixoApuFVoHowpRsNceH0RXpDuY7THozZGmGKGP94u7E",
    logo: Yahoo,
  },
  {
    id: "Prai",
    link: "https://pr.ai/showthread.php?t=23745&s=12d3f35546d5d20d4ce7a37752606725",
    logo: Prai,
  },
  {
    id: "Financial Times",
    link: "https://markets.ft.com/data/announce/detail?dockey=600-202305050830PR_NEWS_USPRX____VA91593-1",
    logo: FinancialTimes,
  },
  {
    id: "Crunchbase",
    link: "https://www.crunchbase.com/organization/metachain-technologies",
    logo: CB,
  },
  {
    id: "Bloomberg",
    link: "https://www.bloomberg.com/press-releases/2022-03-02/ese-s-metaverse-division-partners-with-metachain-technologies-to-develop-applications-on-the-blockchain-and-in-the-metaverse",
    logo: Bloomberg,
  },
  {
    id: "ese gaming",
    link: "https://esegaming.com/eses-metaverse-division-partners-with-metachain-technologies/",
    logo: ESE,
  },
  {
    id: "newswire",
    link: "https://www.newswire.ca/news-releases/global-ai-newswire-achieves-first-ai-newswire-business-milestone-890687480.html",
    logo: Cision,
  },
].reverse();
