import styles from "@/styles/home.module.scss";
import { MetaTags } from "@/components/common/Meta";
import { homeSeo } from "@/configs/seo.config";
import { Container } from "react-bootstrap";
import Header from "@/components/Layout/Header";
import Patnership from "@/components/Home/Partnership";
import News from "@/components/Home/News";
import Blog from "@/components/Home/Blog";
import Team from "@/components/Home/Team";
import Projects from "@/components/Home/Projects";
import Services from "@/components/Home/Services";
import Hero from "@/components/Home/Hero";
import ScrollTrigger from "react-scroll-trigger";
import { isCSR } from "@/utils/helpers";
import { useRef } from "react";

export default function Home() {
  const underHeroRef = useRef(null);
  const animateServices = () => {
    if (!isCSR() || !underHeroRef.current) return;
    const underHeroRefCurrent = underHeroRef.current as HTMLElement;
    underHeroRefCurrent.style.transform = "translateY(0)";
    underHeroRefCurrent.style.transition = "transform 1s ease-in-out";
  };
  return (
    <>
      <MetaTags {...homeSeo} />
      <div className='homeFlex'>
        <Container fluid className={styles.heroSection}>
          <Header />
          <Hero />
        </Container>
        {/* @ts-ignore */}
        <ScrollTrigger onEnter={() => animateServices()}>
          <Container fluid className='underHero' ref={underHeroRef}>
            <Services />
          </Container>
        </ScrollTrigger>
        <Projects />
        <Container fluid>
          <Team />
        </Container>
        <Container fluid className='px-0 '>
          <Blog />
        </Container>
        <Container fluid>
          <News />
        </Container>
        <Container fluid className='px-0 mx-0'>
          <Patnership />
        </Container>
      </div>
    </>
  );
}
