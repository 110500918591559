import MercyImg from "@/assets/img/partner/mercycorp.svg";
import GoogleImg from "@/assets/img/partner/Google.svg";
import CanadaImg from "@/assets/img/partner/Canada.svg";
import SweedImg from "@/assets/img/partner/sweed.svg";
import NetherlandsImg from "@/assets/img/partner/Netherlands-HR.svg";
import EnabelImg from "@/assets/img/partner/Enabel.svg";
import TechstarsImg from "@/assets/img/partner/cc.svg";
import UpworkImg from "@/assets/img/partner/66.svg";
import StripeImg from "@/assets/img/partner/gf.svg";
import IntersectHubImg from "@/assets/img/partner/99-300x164.svg";
import AwsImg from "@/assets/img/partner/aws.svg";
import HerokuImg from "@/assets/img/partner/hhgf.svg";
import ApicImg from "@/assets/img/partner/5-1.svg";
import AsfariFoundationImg from "@/assets/img/partner/Full-logo-on-blue-1568x617.svg";
import PaltelGroupImg from "@/assets/img/partner/11-300x234.svg";
import IbtikarFundImg from "@/assets/img/partner/9.svg";
import KasImg from "@/assets/img/partner/10.svg";
import AngelHackImg from "@/assets/img/partner/gsg.svg";
import BaytImg from "@/assets/img/partner/7.svg";
import FoundersAndCodersImg from "@/assets/img/partner/logo-768x338.svg";
import GithubImg from "@/assets/img/partner/8.svg";
import SharedStudiosImg from "@/assets/img/partner/12-300x49.svg";
import TechnovationChallengeImg from "@/assets/img/partner/33.svg";
import SalesForceImg from "@/assets/img/partner/f.svg";

export const PartnerData = [
  {
    id: "mercycorp",
    logo: MercyImg,
    link: "https://www.mercycorps.org/",
  },
  {
    id: "google",
    logo: GoogleImg,
    link: "https://startup.google.com/",
  },
  {
    id: "Canada",
    logo: CanadaImg,
    link: "https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=eng",
  },
  {
    id: "sweed",
    logo: SweedImg,
    link: "https://www.sida.se/en",
  },
  {
    id: "Netherlands",
    logo: NetherlandsImg,
    link: "https://www.netherlandsandyou.nl/",
  },
  {
    id: "Enabel",
    logo: EnabelImg,
    link: "https://www.wehubit.be/en/node/68",
  },
  {
    id: "techstars",
    logo: TechstarsImg,
    link: "http://diversity.techstars.com/foundation",
  },
  {
    id: "upwork",
    logo: UpworkImg,
    link: "https://upwork.com",
  },
  {
    id: "stripe",
    logo: StripeImg,
    link: "https://stripe.com/",
  },
  {
    id: "intersect hub",
    logo: IntersectHubImg,
    link: "https://intersecthub.org/",
  },
  {
    id: "aws",
    logo: AwsImg,
    link: "https://aws.amazon.com/",
  },
  {
    id: "heroku",
    logo: HerokuImg,
    link: "https://www.heroku.com/",
  },
  {
    id: "apic",
    logo: ApicImg,
    link: "http://www.apic.ps/En",
  },
  {
    id: "asfari foundation",
    logo: AsfariFoundationImg,
    link: "https://www.asfarifoundation.org.uk/",
  },
  {
    id: "paltel group",
    logo: PaltelGroupImg,
    link: "http://www.paltelgroup.ps/",
  },
  {
    id: "ibtikar fund",
    logo: IbtikarFundImg,
    link: "https://ibtikarfund.com/",
  },
  {
    id: "kas",
    logo: KasImg,
    link: "https://www.kas.de/de/home",
  },
  {
    id: "angel hack",
    logo: AngelHackImg,
    link: "https://angelhack.com/",
  },
  {
    id: "bayt",
    logo: BaytImg,
    link: "https://www.bayt.com/",
  },
  {
    id: "founders and coders",
    logo: FoundersAndCodersImg,
    link: "https://www.foundersandcoders.com/",
  },
  {
    id: "github",
    logo: GithubImg,
    link: "https://github.com/",
  },
  {
    id: "shared studios",
    logo: SharedStudiosImg,
    link: "http://www.sharedstudios.com/",
  },
  {
    id: "technovation challenge",
    logo: TechnovationChallengeImg,
    link: "https://technovationchallenge.org/",
  },
  {
    id: "sales force",
    logo: SalesForceImg,
    link: "https://www.salesforce.com/",
  },
];
