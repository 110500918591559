import Image from "next/image";
import { FC } from "react";
import { Card } from "react-bootstrap";
import { Icons } from "@/configs/icons.config";

export const TeamCard: FC<any> = ({ name, job, breif, img, bgImg, socialMedia }) => {
  const elementStyle = {
    backgroundImage: `url(${bgImg.src})`,
    backgroundSize: "cover",
    paddingTop: "30px",
    overflow: "hidden",
  };
  return (
    <Card className='team-item cursor-pointer'>
      <div style={elementStyle} className='bg-team'>
        <Image className='homeTeam img-fluid' {...img} width={0} height={0} quality={100} />
      </div>
      <Card.Body>
        <Card.Title className={`text-white truncate `}>{name}</Card.Title>
        <Card.Subtitle className='color-primary mb-3'>{job}</Card.Subtitle>
        <Card.Text as='div'>
          <p className='text-sm truncate me-2'>{breif}</p>
          <div className='text-center d-flex'>
            {Object.entries(socialMedia).map(([key, value]) => (
              <a
                href={value as string}
                target='_blank'
                rel='noopener noreferrer'
                className=' me-2 linkedin-team text-white'
                key={key}
              >
                {Icons[key as keyof typeof Icons]}
              </a>
            ))}
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
