// @ts-ignore
import { routes } from "@/configs/navlink.config";

import softwareImg from "@/assets/img/services/software.png";
import aiImg from "@/assets/img/services/ai.png";
import blockchainImg from "@/assets/img/services/bc.png";

export const services = [
  {
    name: "Software Development",
    breif: "Unlock growth with our premier software development services",
    content:
      "At Metachain Technologies Inc., we're not just about code - we're about crafting solutions that drive your success. From startups to established businesses.",
    path: routes.softwareDevelopment,
    img: softwareImg,
  },
  {
    name: "AI Integration",
    breif: "For Secure, Transparent, and Efficient Operations",
    content:
      "At Metachain Technologies Inc., we're not just about code - we're about crafting solutions that drive your success. From startups to established businesses.",
    path: routes.ai,
    img: aiImg,
  },
  {
    name: "Blockchain Technologies",
    breif: "Automate, Decide, and Delight",
    content: `For those in need of a private blockchain development, or decentralized apps (dApps), and customized smart contracts, we offer white label solutions which guarantees security, improve transparency, and maximize efficiency.`,
    path: routes.blockchain,
    img: blockchainImg,
  },
];
