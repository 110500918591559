import { routes } from "@/configs/navlink.config";

export const projectsData = [
  {
    id: "Televerse",
    name: "Televerse",
    content: [
      `In Televerse, we envisioned an expansive virtual world teeming with interaction, exploration, and entertainment. Users can engage with Televerse's NFT land marketplace, events system, digital currency ecosystem, and ICO system.`,
      `Additionally, our unique DAO integration allows users to actively shape the platform's evolution, creating a truly democratic Metaverse.`,
    ],
    link: "https://dev.televerse.me/",
    linkName: "https://www.televerse.me",
    impact: "Empowered users; immersive, interactive Metaverse experience.",
    deliverable: {
      "integrated Metaverse platform": routes.metaverse,
      "NFT land marketplace": routes.nft,
      "integrated events system": routes.whiteLabel,
      "crypto currency eco-system": routes.cryptoWallet,
      "ICO system": routes.whiteLabel,
    },
  },
  {
    id: "RevitalistLifestyle",
    name: "Revitalist Lifestyle",
    content: [
      `Revitalist Lifestyle, a prominent US-based wellness entity, broadened its virtual presence through a strategic collaboration with us.`,
      `Metachain's expertise yielded a virtual Metaverse Clinic, a digital reflection of Revitalist's physical clinics, that transcends geographical boundaries, giving users the flexibility to access comprehensive treatments and interact with professionals from the confines of their homes.`,
    ],
    link: "https://www.revitalist.com",
    linkName: "https://www.revitalist.com",
    impact: "Unrestricted access to quality healthcare.",
    deliverable: { "MetaVerse Clinic": routes.metaverse },
  },
  {
    id: "ESEEntertainment",
    name: "ESE Entertainment",
    content: [
      `Our partnership with ESE Entertainment, a leading European esports and technology organization, led to the birth of an innovative NFT marketplace within the Metaverse.`,
      `We devised a platform where creators could forge unique sports content NFTs, allowing fans to interact with their favorite sports in novel ways. This marketplace built by our blockchain development company has set new standards in the sports media space.`,
    ],
    link: "https://www.esegaming.com",
    linkName: "https://www.esegaming.com",
    impact: "Revolutionary sports content engagement and creator-fan interaction.",
    deliverable: { "NFT marketplace": routes.nft },
  },
  {
    id: "Xenomics",
    name: "Xenomics",
    content: [
      `Metachain joined forces with Xenomics, a pioneering bio-engineering firm, to leverage AI systems and sophisticated data analysis techniques.`,
      `Our software development company's contributions empowered Xenomics to extract value from their massive datasets, thereby significantly enhancing their business operations.`,
    ],
    link: "https://www.xenomics.com",
    linkName: "https://www.xenomics.com",
    impact: "Informed decision making for Xenomics, thanks to insights gleaned from big data.",
    deliverable: { "AI system": routes.ai },
    current: true,
  },
  {
    id: "GlobalAI",
    name: "Global AI",
    content: [
      `With Global AI, we transformed the press release landscape. Our AI-powered platform condensed lengthy press releases from various companies into concise points, ensuring significant information remained intact.`,
      `This white-label solution, by our web3 development company, expedited and enriched communication between companies and their audiences.`,
    ],
    link: "https://globalnewswire.ai",
    linkName: "https://globalnewswire.ai",
    impact: "Streamlined communication; Enhanced press release engagement.",
    deliverable: { "AI model": routes.ai, "specialized platform": routes.blockchain },
    current: true,
  },
  {
    id: "GenAI",
    name: "GenAI",
    content: [
      `With Global AI, we transformed the press release landscape. Our AI-powered platform condensed lengthy press releases from various companies into concise points, ensuring significant information remained intact.`,
      `This white-label solution by our web3 development company, expedited and enriched communication between companies and their audiences.`,
    ],
    link: "https://www.genai-solutions.com",
    linkName: "https://www.genai-solutions.com",
    impact: "Augmented communication and engagement across various sectors.",
    deliverable: { "AI chat voice": routes.ai },
    current: true,
  },
].reverse();
