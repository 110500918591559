import { blogArticles } from "@/content/blog.content";
import { Col, Row } from "react-bootstrap";
import { BlogCard } from "../Blog/Card";
import styles from "@/styles/blog.module.scss";
import Slider from "react-slick";
import { reviewsCarouselSetting } from "@/configs/slider.config";

const Blog = () => {
  return (
    <>
      <div className={` ${styles.blur} mt-20`}>
        <Row className={`${styles.card} pb-20 mt-5`}>
          <h1 className={`text-center mb-5 ${styles.h1} `}>
            Latest articles and <span className='text-gradient '>news</span>
          </h1>
          <Col md={10} xl={8} className='mx-auto'>
            <Row className='d-none d-md-flex'>
              {blogArticles.slice(0, 3).map((item) => (
                <Col md={4} key={item.slug}>
                  <BlogCard {...item.summerizedData} />
                </Col>
              ))}
            </Row>
            <Row className='justify-content-center align-items-center mt-4 d-flex d-md-none container-like'>
              <Col sm={10}>
                <Row className='slider-theme owl-theme owl-stage-outer-pb-5 pb-0'>
                  <Slider {...reviewsCarouselSetting}>
                    {blogArticles.slice(0, 3).map((item) => (
                      <BlogCard key={item.slug} {...item.summerizedData} />
                    ))}
                  </Slider>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Blog;
