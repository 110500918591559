import React from "react";
import { Col, Row } from "react-bootstrap";
import { PartnerData } from "@/configs/partners.config";
import Image from "next/image";
import Circle from "@/assets/svg/circle-p.svg";
import styles from "@/styles/partner.module.scss";

const LogoTicker = ({
  data,
  reverse,
}: {
  data: { id: string; logo: string; link: string }[];
  reverse?: boolean;
}) => {
  const neverEndData = new Array(100).fill(data).flat();

  return (
    <div className={`${styles.ticker} mb-4`}>
      <div className={`${styles.tickerItem} ${reverse ? styles.tickerReverse : ""}`}>
        {neverEndData.map((item, ix) => (
          <a href={item.link} rel='noreferrer' target='_blank' key={item.id + ix}>
            <Image
              src={item.logo}
              className='Partenr'
              alt={`${item.id} logo`}
              loading='lazy'
              quality={50}
              // width={0}
              // height={0}
              sizes='100vw'
              style={{ maxWidth: "70px", height: "auto", marginInline: "24px" }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};
const PartnerLogos = () => {
  // divide the array into two
  const half = Math.ceil(PartnerData.length / 2);
  const leftSide = PartnerData.slice(0, half);
  const rightSide = PartnerData.slice(-half);

  return (
    <div className={styles["masked-dev"]}>
      <div className='masked-content'>
        <LogoTicker data={leftSide} />
        <LogoTicker data={rightSide} reverse={true} />
      </div>
    </div>
  );
};

function Patnership() {
  return (
    <>
      <Row
        className={`justify-content-center align-items-center ${styles.partnerContainer} m-0 p-0`}
        id='Partners'
      >
        <Col md={8} className='pt-5 mx-0 px-0'>
          <Row>
            <Image
              src={Circle}
              className='partenr-img mx-auto w-50 '
              alt={`Circle decoration`}
              loading='lazy'
              quality={100}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Row>
        </Col>
      </Row>
      <Row className={`justify-content-center text-center ${styles["partner-content"]}`}>
        <h1 className='mb-10'>Partnership</h1>
        <Col lg={12} xs={11} md={10} className='mb-10'>
          <p className='mb-0'>
            <strong>Metachain</strong> has partnered with MERCY CORPS to obtain top talent Metaverse
            Developers at an affordable rate.
          </p>
          <p>
            <strong>MERCY CORPS</strong> have partnered with some of the largest tech companies
            globally.
          </p>
        </Col>
      </Row>

      <Row className='mt-2 mb-20'>
        <PartnerLogos />
      </Row>
    </>
  );
}

export default Patnership;
